<template>
  <div>
    <v-container> Under Construction </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  components: {},
};
</script>

<style>
</style>